import { Config } from './interfaces';

/**
 * @namespace
 * @ignore
 */
const config: Config = {
  urls: {
    portal: 'https://track.data.airasia.com/relay/query'
  },
  allowedEnvs: ['development', 'production']
};

export default config;