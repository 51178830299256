import axios from 'axios';
import { AttributeCollection } from './interfaces';

/**
 * @class ValueAccessor
 * 
 * @description
 * Used to access values of different variables set in portal.
 */
class ValueAccessor {
  /**
   * @ignore
   */
  portalUrl: string;
  /**
   * @ignore
   */
  env: string;

  /**
   * Creates a new value accessor object.
   * 
   * @param portalUrl 
   * @param env 
   * 
   * @example
   * 
   * ```
   * const valueAccessor = new ValueAccessor('https://portal.data.com', 'development');
   * ```
   */
  constructor(portalUrl: string, env: string) {
    this.portalUrl = portalUrl;
    this.env = env;
  }

  /**
   * Get value of a variable, affected by given attributes.
   *
   *
   * @param variableName The variable name as created in portal
   * @param attributes AttributeCollection object, containing different attributes and
   * their values that affect the value of the given variable.
   *
   * @returns A promise which gets resolved to the value of the variable.
   *
   * @example
   * 
   * ```
   * const response = await valueAccessor.get('test_string_dotcom',
   *                             {userId: 'sso0112314', age: 12});
   * // response will either be a valid value or undefined
   * ```
   */
  async getValue(variableName: string, attributes: AttributeCollection) {
    const valueResponse = await this.getValues([variableName], attributes);
    return valueResponse[variableName];
  }

  /**
   * Get values of all the variable names given as array, affected by given attributes.
   * 
   * 
   * @param variableNames The variable names as created in portal
   * @param attributes AttributeCollection object, containing different attributes and
   * their values that affect the value of the given variable.
   * 
   * @returns A promise which gets resolved to an object.
   * 
   * @example
   * 
   * ```
   * const valueResponse = await valueAccessor.getAll(['test_string_dotcom'],
   *                             {userId: 'sso0112314', age: 12});
   * // valueResponse['test_string_dotcom'] should contain the value
   * ```
   */
  async getValues(variableNames: [string], attributes: AttributeCollection) {
    const payload = {
      vars: variableNames,
      env: this.env,
      tags: attributes
    };
    return axios.post(this.portalUrl, payload)
      .then((response: any) => this._flattenResponse(response.data));
  }

  /**
   * Flatten response from portal into acceptable.
   *
   * @param response
   * 
   * @ignore
   * @private
   */
  _flattenResponse(data: any) {
    return Object.keys(data).reduce((p, k) => {
      p[k] = data[k].value;
      return p;
    }, {});
  }
}

export default ValueAccessor;
